import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import { errorMessage } from '@firsttable/functions';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import withLocation from '../../../hocs/location';

const UPDATE_SPAM_EMAIL = gql`
 mutation updateSpamEmail($email: String!, $sendEmail: Boolean!) {
   updateSpamEmail(email: $email, sendEmail: $sendEmail) {
     id
     hasBouncedEmail
     hasMarkedAsSpam
   }
 }
`;

const SpamEmailForm = (props) => (
  <FormLayout {...props} mt="20px">
    <Field
      component={FormField}
      mb="25px"
      type="email"
      name="email"
      id="Email"
      label="Update your email on record"
    />
    <Field
      component={FormField}
      type="checkbox"
      name="sendEmail"
      label="Retry sending emails to me"
    />
  </FormLayout>
);

SpamEmailForm.defaultProps = {};

SpamEmailForm.propTypes = {
  status: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default compose(
  withLocation,
  graphql(UPDATE_SPAM_EMAIL, { name: 'updateSpamEmailMutation' }),
  withFormik({
    mapPropsToValues: () => ({
      email: '',
      sendEmail: false,
    }),
    validationSchema: () => Yup.object().shape({
      email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    }),
    handleSubmit: async (
      values, {
        setStatus, setSubmitting, resetForm, props: { updateSpamEmailMutation },
      },
    ) => {
      const { error, data } = await updateSpamEmailMutation({
        variables: { ...values },
      }).catch((e) => {
        setStatus({ message: errorMessage(e), type: 'danger' });
        setSubmitting(false);
        return false;
      });
      if (error) {
        setStatus({ message: errorMessage(error), type: 'danger' });
        setSubmitting(false);
      }
      if (data) {
        const { updateSpamEmail: { hasBouncedEmail, hasMarkedAsSpam } } = data;
        resetForm();
        setSubmitting(false);
        if (!hasBouncedEmail && !hasMarkedAsSpam) {
          setStatus({ message: 'Updated email information', type: 'success' });
        } else {
          setStatus({ message: 'There was error updating your information', type: 'danger' });
        }
      }
      return true;
    },
  }),
)(SpamEmailForm);
