import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { errorMessage, scrollTo } from '@firsttable/functions';
import { flowRight as compose } from 'lodash';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import {
  getLiveUser as GET_LIVE_USER_QUERY,
  updateLiveUser as UPDATE_LIVE_USER_QUERY,
} from '@firsttable/graphql-queries/src';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import {
  Button,
  BodyTitle,
  Box,
  Text,
  Divider,
} from '@firsttable/web-components/atoms';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import PageLoading from '@firsttable/web-components/molecules/PageLoading';
import Logger from '../../../helpers/logger';
import ModalContext from '../../../context/ModalContext';
import DeleteAccount from '../Auth/DeleteAccount';

const logger = new Logger('Profile Form');

const ProfileForm = ({
  isSubmitting,
  // values,
  data: { loading },
  ...props
}) => {
  const { showModalFunc } = useContext(ModalContext);
  if (loading) {
    return <PageLoading />;
  }
  return (
    <FormLayout {...props}>
      <Field
        component={FormField}
        type="text"
        id="Name"
        name="firstName"
        label="First name"
        mb={['15px', '30px']}
      />
      <Field
        component={FormField}
        type="text"
        id="Surname"
        name="surname"
        label="Surname"
        mb={['15px', '30px']}
      />
      <Field
        component={FormField}
        id="Email"
        name="email"
        label="Email for reservations"
        size="l"
        type="email"
        mb={['15px', '30px']}
      />
      <BodyTitle size="m" m="20px 0">
        Update your password
      </BodyTitle>
      <Field
        component={FormField}
        name="oldPassword"
        id="OldPassword"
        label="Current password"
        type="password"
        placeholder="Current password"
        mb={['15px']}
      />
      <Field
        component={FormField}
        name="password"
        id="Password"
        type="password"
        label="New password"
        placeholder="New password"
        mb={['15px']}
      />
      <Field
        component={FormField}
        name="confirmPassword"
        id="Confirm"
        type="password"
        label="Confirm new password"
        placeholder="Confirm password"
        mb={['15px', '30px']}
      />
      <BodyTitle size="m" m="20px 0">
        Social preferences for communication
      </BodyTitle>
      <Text color="grayscale.600">
        We want to ensure we’re sending you relevant content, so please tick the
        appropriate box if you’re not a social media user.
      </Text>
      <Field
        component={FormField}
        type="checkbox"
        name="noFacebook"
        id="noFacebook"
        label="I don't use Facebook"
      />
      <Field
        component={FormField}
        type="checkbox"
        name="noInstagram"
        id="noInstagram"
        label="I don't use Instagram"
      />
      <BodyTitle size="m" m="20px 0">
        Contact Consent
      </BodyTitle>
      <Field
        component={FormField}
        type="radio"
        name="contactPermission"
        id="contactPermission"
        label="One of these please"
        options={[
          { inputLabel: 'No thanks (not recommended)', inputValue: '0' },
          { inputLabel: 'Yes please', inputValue: '1' },
        ]}
      />
      {/* <Field */}
      {/*  component={FormField} */}
      {/*  defaultValue={values.avatarURL} */}
      {/*  name="profileImage" */}
      {/*  id="Profile" */}
      {/*  type="image" */}
      {/* /> */}
      <Box textAlign="center" mt="32px">
        <Button
          wide
          maxWidth="160px"
          size="m"
          kind="cta"
          m={10}
          mb={0}
          type="submit"
          isLoading={isSubmitting}
        >
          Update
        </Button>
      </Box>
      <Divider m="20px 0" />
      <Box textAlign={['center', 'center', 'left']}>
        <BodyTitle size="m" mb={20}>
          Deactivate your account
        </BodyTitle>
        <Text color="grayscale.600">
          Are you sure you want to say goodbye to 50% off early bird dining?
          Deactivating your account will permanently delete your profile and
          reservations associated with this account.
        </Text>
        <Button
          wide
          maxWidth="160px"
          size="m"
          kind="outline"
          type="button"
          onClick={() => showModalFunc(<DeleteAccount />, { type: 'narrow' })}
        >
          Continue
        </Button>
      </Box>
    </FormLayout>
  );
};

ProfileForm.propTypes = {
  updateLiveUser: PropTypes.func.isRequired,
  // values: PropTypes.shape().isRequired,
  isSubmitting: PropTypes.bool,
  data: PropTypes.shape(),
};

ProfileForm.defaultProps = {
  isSubmitting: false,
};

export default compose(
  graphql(GET_LIVE_USER_QUERY, { options: { fetchPolicy: 'network-only' } }),
  graphql(UPDATE_LIVE_USER_QUERY, { name: 'updateLiveUser' }),
  withFormik({
    enableReinitialize: true, // so we can use network-only
    mapPropsToValues: ({ data: { user } }) => ({
      ...user,
      contactPermission: user?.contactPermission ? '1' : '0',
      oldPassword: '',
      confirmPassword: '',
      password: '',
    }),
    validationSchema: () =>
      Yup.object().shape({
        firstName: Yup.string().required('Required'),
        surname: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        // oldPassword: Yup.string().min(8),
        password: Yup.string().when('oldPassword', {
          is: (val) => val && val.length > 0,
          then: Yup.string().required(),
        }),
        confirmPassword: Yup.string().when('password', {
          is: (val) => val && val.length > 0,
          then: Yup.string()
            .oneOf([Yup.ref('password')], 'Both passwords need to be the same')
            .required(),
        }),
      }),
    handleSubmit: async (
      values,
      { setSubmitting, setStatus, props: { updateLiveUser } },
    ) => {
      setStatus(false);
      try {
        const { data, error } = await updateLiveUser({
          variables: {
            userInput: {
              firstName: values.firstName,
              surname: values.surname,
              email: values.email,
              noFacebook: values.noFacebook,
              noInstagram: values.noInstagram,
              termsAndPrivacy: values.termsAndPrivacy,
              oldPassword: values.oldPassword,
              password: values.password,
              confirmPassword: values.confirmPassword,
              contactPermission: +values.contactPermission === 1,
            },
          },
        });
        logger.debug('Profile Data', values, data);
        setSubmitting(false);
        if (error) {
          setStatus({ message: errorMessage(error), type: 'danger' });
        } else {
          setStatus({ message: 'Successfully updated!', type: 'success' });
        }
      } catch (e) {
        setSubmitting(false);
        setStatus({ message: errorMessage(e), type: 'danger' });
      }
      scrollTo('form-alert', -20);
    },
  }),
)(ProfileForm);
