import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Box from '@firsttable/web-components/atoms/Box';
import BodyTitle from '@firsttable/web-components/atoms/BodyTitle';
import Icon from '@firsttable/web-components/atoms/Icon';

const AccordionSection = styled(Box)``;
const AccordionHeader = styled(BodyTitle)``;

const AccordionContent = styled.div`
  ${({ scrollHeight, active }) => (!active ? css`height: 0;` : css`height: ${scrollHeight}px;`)};
  overflow: hidden;
  transition: height 0.2s ease;
  margin-bottom: ${(props) => (props.active ? `${themeGet('space.m')(props)}px` : null)};
`;

const Accordion = ({ title, children, isActive }) => {
  const [active, setActiveState] = useState(false);
  const [scrollHeight, setScrollHeight] = useState('0');
  const content = useRef(null);

  useEffect(() => {
    if (isActive) {
      setActiveState(true);
    }
    setScrollHeight(content.current.scrollHeight);
  }, []);
  const changeStatus = () => {
    setActiveState(!active);
  };
  return (
    <AccordionSection>
      <AccordionHeader>
        <Box display="inline-flex" alignItems="center" role="button" onClick={() => changeStatus()}>
          <Box mr={5}>{title}</Box>
          <Icon
            color="black"
            css={{ flexShrink: 0 }}
            name={`s-arrow-${!active ? 'down' : 'up'}`}
            top={2}
          />
        </Box>
      </AccordionHeader>
      <AccordionContent ref={content} scrollHeight={scrollHeight} active={active}>
        {children}
      </AccordionContent>
    </AccordionSection>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
};

Accordion.defaultProps = {
  isActive: false,
};
export default Accordion;
