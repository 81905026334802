import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  Title,
} from '@firsttable/web-components/atoms';

import { navigate } from 'gatsby';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { withApollo } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import withLocation from '../../../hocs/location';
import Logger from '../../../helpers/logger';
import ModalContext from '../../../context/ModalContext';

const logger = new Logger('Delete Account');

const DELETE_USER = gql`
  mutation deleteUser {
    deleteUser {
      message
      code
    }
  }
`;

const DeleteAccount = ({ client }) => {
  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);
  const { hideModalFunc } = useContext(ModalContext);

  const deleteAccount = async () => {
    logger.debug('deleting account');
    client
      .mutate({ mutation: DELETE_USER })
      .then((r) => {
        logger.debug('Success: Delete response', r);
        hideModalFunc();
        // reset apollo client store to defaults
        client.resetStore();
        // handle login redirect
        setHasBeenDeleted(true);
      })
      .catch((e) => logger.error(e));

    if (hasBeenDeleted) {
      await navigate('/');
    }
  };

  return (
    <Flex height="100vh">
      <Box m="auto">
        <Box
          position="relative"
          width="100%"
          maxWidth="508px"
          p={['40px 16px 32px', '64px']}
          bg="white"
        >
          <Title size="h5" mb="13px">
            Deactivate your account
          </Title>
          <Text color="grayscale.600" textAlign="center" fontSize="s" mb="10px">
            We’re sad to see you go. Please confirm that you want to deactivate
            your account below.
          </Text>
          <Divider mb="16px" display={[null, 'none']} />

          <Flex mt={['24px', '40px']}>
            <Button
              mr={10}
              data-testid="conditions-btn"
              onClick={() => hideModalFunc()}
              size="l"
              fontWeight="600"
            >
              Go back
            </Button>
            <Button
              kind="cta"
              wide
              size="l"
              fontWeight="600"
              onClick={() => deleteAccount()}
            >
              Deactivate account
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

DeleteAccount.propTypes = {
  client: PropTypes.object.isRequired,
};

export default compose(withLocation, withApollo)(DeleteAccount);
