import React, { useContext, useEffect } from 'react';
import { Router } from '@reach/router';
import { BodyTitle, Text, List } from '@firsttable/web-components/atoms';
import { graphql, useStaticQuery } from 'gatsby';
import { countFormat, roundToClosest } from '@firsttable/functions';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { RowHead } from '@firsttable/web-components/molecules';
import PrivateRoute from '../components/PrivateRoute';
import Reservations from '../components/organisms/Reservations';
import AccountBalances from '../components/organisms/AccountBalance';
import Subscriptions from '../components/organisms/Subscriptions';
import ProfileForm from '../components/organisms/Forms/ProfileForm';
import ProfileLayout from '../layouts/profileLayout';
import PromoCodeForm from '../components/organisms/Forms/PromoCodeForm';
import MissingRoute from '../layouts/MissingRoute';
import SpamEmailForm from '../components/organisms/Forms/SpamEmailForm';
import AuthContext from '../context/AuthContext';
import LoadingLayout from '../layouts/LoadingLayout';
import FullWidthLayout from '../layouts/FullWidthLayout';
import { isGlobalSite } from '../helpers/data';
import SEO from '../components/seo';

const CREATE_TOKEN_LOGIN = gql`
  mutation createTokenLogin($token: String!) {
    createTempMemberLogin(token: $token) {
      token
      member {
        id
        firstName
        email
        surname
        avatarURL
        termsAndPrivacy
        contactPermission
        city {
          id
          menuTitle
          slug
        }
        upcomingBookings {
          edges {
            node {
              restaurant {
                id
              }
              availability {
                id
                session
              }
              date
              time
              status
            }
          }
        }
      }
    }
  }
`;

const AccountBalance = () => (
  <ProfileLayout>
    <AccountBalances />
  </ProfileLayout>
);

const PromoCodes = ({ location }) => (
  <ProfileLayout>
    <BodyTitle size="m">Promo Codes</BodyTitle>
    <Text>
      1. Share your promo code with friends and family and they&apos;ll get $5
      free credit to get them started
    </Text>
    <Text>
      2. You&apos;ll earn $5 free credit when your friends and family make their
      first booking
    </Text>
    <PromoCodeForm location={location} />
  </ProfileLayout>
);
PromoCodes.propTypes = {
  location: PropTypes.object,
};

const ProfileFormLayout = () => (
  <ProfileLayout>
    <ProfileForm />
  </ProfileLayout>
);

const ReservationsLayout = () => (
  <ProfileLayout>
    <Reservations />
  </ProfileLayout>
);

const SubscriptionsLayout = () => {
  const { siteConfig } = useStaticQuery(graphql`
    query subscriptionSiteId {
      siteConfig {
        siteId
        restaurantCount
      }
    }
  `);
  return (
    <ProfileLayout>
      <Text>
        Did you know you can use your First Table account at over{' '}
        {countFormat(roundToClosest(siteConfig.restaurantCount, 100))}{' '}
        restaurants around the world? You will receive marketing emails for
        restaurant launches, giveaways or new city updates for all cities ticked
        below. You can update these preferences at any time.
      </Text>
      <Subscriptions siteId={siteConfig.siteId} />
    </ProfileLayout>
  );
};

const UnSubscribeLayout = (props) => (
  <FullWidthLayout align="left">
    <RowHead title="Manage your subscriptions" />
    <Subscriptions siteId={+process.env.GATSBY_SITE_ID} {...props} />
  </FullWidthLayout>
);

const EmailSettings = () => (
  <ProfileLayout>
    <BodyTitle size="m">Email Settings</BodyTitle>
    <Text>
      We tried emailing you and it didn&lsquo;t work. This can be due to the{' '}
      email being marked as spam or it has bounced. To ensure you are getting{' '}
      emails from us for reservations, new restaurants and newsletter update,{' '}
      here are some reasons why:
    </Text>

    <List>
      <li>
        The email address was invalid or doesn&lsquo;t exist. This could mean{' '}
        the domain your email belongs to has expired or your email was entered
        incorrectly
      </li>
      <li>Your email inbox was full</li>
      <li>You have an auto-responder that rejected the email</li>
      <li>
        The email was marked as spam. This could have been occurred
        accidentally, please contact your email service provider to notify them
        our emails are not spam in order for us to restore sending emails
      </li>
      <li>
        Once you think you have resolved the issue causing the bounce or spam{' '}
        notification, please use the form below to help us retry sending emails
        to you.
      </li>
    </List>
    <SpamEmailForm />
  </ProfileLayout>
);

const TokenHandler = ({ token }) => {
  const { handlePostLogin, handleLogin } = useContext(AuthContext);
  const [createTokenLogin, { loading }] = useMutation(CREATE_TOKEN_LOGIN, {
    variables: {
      token,
    },
    fetchPolicy: 'no-cache',
    ssr: false,
  });
  useEffect(() => {
    if (token) {
      createTokenLogin()
        .then(({ data }) => {
          if (data) {
            handleLogin({
              member: data.createTempMemberLogin.member,
              token: data.createTempMemberLogin.token,
              isLoggedIn: true,
            });
            // post login processing
            handlePostLogin({ isLoggedIn: true });
          }
        })
        .catch(() => {});
    }
  }, [createTokenLogin, handleLogin, handlePostLogin, token]);
  if (loading) {
    return <LoadingLayout showFooter showMenu />;
  }
  return <MissingRoute />;
};

TokenHandler.propTypes = {
  token: PropTypes.string,
};

TokenHandler.defaultProps = {
  token: null,
};

const Profile = () => {
  if (isGlobalSite) {
    return <SEO meta={[{ 'http-equiv': 'refresh', content: '0;url=/' }]} />;
  }
  return (
    <Router>
      <PrivateRoute path="/profile" component={ProfileFormLayout} />
      <PrivateRoute
        path="/profile/reservations"
        component={ReservationsLayout}
      />
      <PrivateRoute
        path="/profile/subscriptions"
        component={SubscriptionsLayout}
      />
      <UnSubscribeLayout path="/profile/unsubscribe/:userKey" />
      <PrivateRoute
        path="/profile/account-balance"
        component={AccountBalance}
      />
      <PrivateRoute path="/profile/promo-codes" component={PromoCodes} />
      <PrivateRoute path="/profile/emails" component={EmailSettings} />
      <TokenHandler path="/profile/:token" />
      <MissingRoute default />
    </Router>
  );
};

Profile.propTypes = {};
Profile.defaultProps = {};

export default Profile;
