import React from 'react';
import gql from 'graphql-tag';
import { BodyTitle } from '@firsttable/web-components/atoms';
import PageLoading from '@firsttable/web-components/molecules/PageLoading';
import Text from '@firsttable/web-components/atoms/Text';
import ErrorDisplay from '@firsttable/web-components/molecules/ErrorDisplay';
import { useQuery } from '@apollo/client';
import ReservationTable from './List/Table';
import NavLink from '../../atoms/NavLink';

const GET_USER_BOOKINGS = gql`
  query getLiveUser {
    user: Member {
      id
      upcomingBookings {
        edges {
          node {
            id
            reference
            currency
            date
            time
            price
            people
            status
            confirmedAt
            cancellationCredit {
              currency
              amount
              reason
              reasonContent
              nonCreditedAmount
            }
            restaurant {
              id
              title
              slug
            }
          }
        }
      }
      pastBookings {
        edges {
          node {
            id
            reference
            date
            time
            status
            people
            hasReview
            misuseReview {
              id
            }
            review {
              id
              comment
            }
            reviews {
              id
              comment
              status
            }
            restaurant {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
`;

const Reservations = () => {
  const { loading, error, data, refetch } = useQuery(GET_USER_BOOKINGS, {
    fetchPolicy: 'network-only',
  });
  if (loading) return <PageLoading />;
  if (error) return <ErrorDisplay NavLinkComponent={NavLink} />;
  const { user } = data;
  return (
    <>
      {data && data.user ? (
        <>
          <BodyTitle size="m">Upcoming Reservations</BodyTitle>
          <ReservationTable
            data={user.upcomingBookings}
            type="upcoming"
            canCancel
            canDownload
            refetch={refetch}
          />
          <BodyTitle size="m" mt="30px" mb="0">
            Past Reservations
          </BodyTitle>
          <Text fontSize="s">Showing the last 12 months of reservations</Text>
          <ReservationTable
            data={user.pastBookings}
            type="past"
            refetch={refetch}
          />
        </>
      ) : (
        <Text>No data</Text>
      )}
    </>
  );
};

Reservations.propTypes = {};
Reservations.defaultProps = {};

export default Reservations;
