import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import media from 'styled-media-query';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import { Box } from '../../atoms';

const Menu = styled(Box)`
  list-style: none;
  font-weight: bold;

  li {
    border-bottom: 1px solid ${themeGet('colors.grayscale.300')};

    &:last-child {
      border-bottom: 0;
    }
  }

  a {
    position: relative;
    display: block;
    padding: 10px 20px 10px 0;
    color: ${themeGet('colors.brand.blue')};
    ${media.greaterThan(bp.s)`
      padding: 16px 20px 16px 0;
    `};

    &::after {
      content: '';
      position: absolute;
      right: 8px;
      top: 50%;
      margin-top: -2px;
      display: block;
      width: 5px;
      height: 5px;
      transform: rotate(45deg) scale(0);
      transition: 0.1s ease transform;
      background: ${themeGet('colors.brand.blue')};
    }

    &:hover:not([active])::after {
      transform: rotate(45deg) scale(1);
    }

    &:active::after {
      background: ${themeGet('colors.gold.900')};
    }
  }

  [active],
  .active {
    color: ${themeGet('colors.brand.red')};

    &::after {
      background: ${themeGet('colors.brand.red')};
      transform: rotate(45deg) scale(1);
    }
  }
`;

const MenuSidebar = (props) => <Menu forwardedAs="ul" {...props} />;

MenuSidebar.propTypes = {
  m: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  p: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
};
MenuSidebar.defaultProps = {
  m: 0,
  p: 0,
};

export default MenuSidebar;
