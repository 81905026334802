import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
} from '@firsttable/web-components/atoms/Table';
import Icon from '@firsttable/web-components/atoms/Icon';
import Text from '@firsttable/web-components/atoms/Text';
import Button from '@firsttable/web-components/atoms/Button';
import ReviewModal from '../../Modal/Reviews';
import ModalContext from '../../../../context/ModalContext';
import ReservationItem from './Item';

const ReservationTable = ({ data, type, refetch, ...props }) => {
  const { showModalFunc, hideModalFunc } = useContext(ModalContext);
  return (
    <Table>
      <THead>
        <Tr>
          <Th width={['100%', '200px']}>Restaurant</Th>
          <Th>Date</Th>
          <Th>Time</Th>
          <Th width={[null, '1px']}>
            <Icon
              name="chair"
              color="black"
              viewBox="0 0 25 40"
              width="1em"
              height="1.6em"
              fontSize="18px"
            />
          </Th>
          {type === 'upcoming' && (
            <>
              <Th width={130} />
              <Th width={130} />
            </>
          )}
          {type === 'past' && <Th width={260} />}
        </Tr>
      </THead>
      <TBody>
        {data.edges ? (
          data.edges.map(({ node }) => (
            <ReservationItem
              key={node.id}
              title={node.restaurant.title}
              slug={node.restaurant.slug}
              date={node.date}
              time={node.time}
              people={node.people}
              booking={node}
              refetch={refetch}
              {...props}
            >
              <Td align="center">
                {type === 'past' && (
                  <>
                    {node.status === 'Confirmed' ? (
                      <>
                        {!node.hasReview && !node.misuseReview && (
                          <Button
                            size="s"
                            onClick={() =>
                              showModalFunc(
                                <ReviewModal
                                  id="ReviewModal"
                                  booking={node}
                                  refetch={refetch}
                                  hideModal={hideModalFunc}
                                />,
                              )
                            }
                            kind="normal"
                          >
                            Leave a review
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        {node.status === 'Cancelled' ? 'Booking cancelled' : ''}
                      </>
                    )}
                  </>
                )}
              </Td>
            </ReservationItem>
          ))
        ) : (
          <Text>No results...</Text>
        )}
      </TBody>
    </Table>
  );
};

ReservationTable.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  refetch: PropTypes.func,
};
ReservationTable.defaultProps = {
  data: {},
  refetch: null,
};

export default ReservationTable;
