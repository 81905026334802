import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tr, Td } from '@firsttable/web-components/atoms/Table';
import Text from '@firsttable/web-components/atoms/Text';
import Button from '@firsttable/web-components/atoms/Button';
import Flex from '@firsttable/web-components/atoms/Flex';
import Box from '@firsttable/web-components/atoms/Box';
import { dayMonthYear, timeOfBooking } from '@firsttable/functions';
import ModalContext from '../../../../context/ModalContext';
import CancellationForm from '../../Forms/CancellationForm';
import {Link} from '@firsttable/web-components/atoms';
import NavLink from '../../../atoms/NavLink';

const BookingCancellationModal = (props) => (
  <Flex height="100vh">
    <Box m="auto">
      <Box
        position="relative"
        width="100%"
        maxWidth="700px"
        p={['40px 16px', '56px 64px']}
        bg="white"
      >
        <CancellationForm {...props} />
      </Box>
    </Box>
  </Flex>
);

const CancelBtn = ({ booking, refetch }) => {
  const { showModalFunc, hideModalFunc } = useContext(ModalContext);
  const isCancelled = booking.status === 'Cancelled';
  return (
    <Button
      wide
      size="s"
      kind="outline"
      onClick={() => {
        showModalFunc(
          <BookingCancellationModal
            hideModal={hideModalFunc}
            booking={booking}
            refetch={refetch}
          />,
          { type: 'narrow' },
        );
      }}
      disabled={isCancelled}
    >
      {isCancelled ? 'Cancelled' : 'Cancel'}
    </Button>
  );
};

CancelBtn.propTypes = {
  booking: PropTypes.shape(),
  refetch: PropTypes.func,
};
CancelBtn.defaultProps = {
  refetch: null,
};

const Item = ({
  title,
  slug,
  date,
  time,
  people,
  canCancel,
  booking,
  children,
  refetch,
}) => (
  <Tr>
    <Td>
      <Text display={[null, 'none']} as="span" fontSize="s">
        Restaurant:
      </Text>{' '}
      <Link to={`${slug}`} NavComponent={NavLink}>
        {title}
      </Link>
    </Td>
    <Td>
      <Text display={[null, 'none']} as="span" fontSize="xs">
        Date:
      </Text>{' '}
      {dayMonthYear(date)}
    </Td>
    <Td>
      <Text display={[null, 'none']} as="span" fontSize="xs">
        Time:
      </Text>{' '}
      {timeOfBooking(time)}
    </Td>
    <Td>
      <Text display={[null, 'none']} as="span" fontSize="s">
        Diners:
      </Text>{' '}
      {people}
    </Td>
    {canCancel && (
      <Td width={[null, '1px']}>
        <CancelBtn booking={booking} refetch={refetch} />
      </Td>
    )}
    {canCancel && (
      <Td width={[null, '1px']}>
        {booking.status !== 'Cancelled' && (
          <Button
            wide
            size="s"
            kind="cta"
            href={`${process.env.GATSBY_SERVER_ENDPOINT}BookingController/${booking.reference}/downloadReservationConfirmation`}
            color="brand.red"
            underline
            download
          >
            Download
          </Button>
        )}
      </Td>
    )}
    {children}
  </Tr>
);

Item.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  people: PropTypes.number,
  canCancel: PropTypes.bool,
  booking: PropTypes.shape(),
  children: PropTypes.node,
  refetch: PropTypes.func,
};
Item.defaultProps = {
  canCancel: false,
};

export default Item;
