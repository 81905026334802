import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { PageLoading } from '@firsttable/web-components/molecules';
import { Link, Text, Title } from '@firsttable/web-components/atoms';
import { useLocation } from '@reach/router';
import SubscriptionForm from './Form';
import NavLink from '../../atoms/NavLink';

const GET_USER_SUBSCRIPTIONS = gql`
  query getUserSubscriptions($userKey: String) {
    user: UserSubscription(key: $userKey) {
      countries {
        edges {
          node {
            id
            country
            siteId
            subscriptions {
              edges {
                node {
                  id
                  title
                  isChecked
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 *
 * @returns {*}
 * @constructor
 * @param country
 * @param props
 */
const Subscriptions = ({
  siteId,
  userKey,
  ...props
}: {
  siteId: number;
  userKey: string;
}) => {
  const { loading, error, data } = useQuery(GET_USER_SUBSCRIPTIONS, {
    variables: { userKey },
    ssr: false,
    fetchPolicy: 'network-only',
  });
  const location = useLocation();
  if (loading) return <PageLoading pb="100px" />;
  if (error) {
    return (
      <>
        <Title is="h3">Whoops, we encountered a problem</Title>
        <Text>
          There was a problem loading your subscriptions. You may not be logged
          in.{' '}
          <Link
            NavComponent={NavLink}
            to={`/auth/sign-in/?backUrl=${encodeURIComponent(
              location.pathname,
            )}`}
            underline
            color="brand.secondary"
          >
            Please try logging in here
          </Link>
          .
        </Text>
      </>
    );
  }
  const {
    user: { countries },
  } = data;

  // sort countries list to show this country first in subscriptions list
  const countriesList: any = {};
  countriesList.edges = [...countries.edges]
    .slice()
    .sort((a: any) => (a.node.siteId === siteId ? -1 : 1));

  return (
    <SubscriptionForm
      countries={countriesList}
      userKey={userKey || null}
      {...props}
    />
  );
};

Subscriptions.propTypes = {
  siteId: PropTypes.number.isRequired,
  userKey: PropTypes.string,
};

Subscriptions.defaultProps = {
  userKey: null,
};

export default Subscriptions;
