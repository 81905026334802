import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { Field, FormikBag, withFormik } from 'formik';
import { ColumnCheckboxGroup } from '@firsttable/web-components/molecules';
import { errorMessage } from '@firsttable/functions';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import { Flex, Box, Button } from '@firsttable/web-components/atoms';
import { BaseFormProps } from '@firsttable/types';
import Accordion from '../../../hooks/accordion';

const UPDATE_USER_SUBSCRIPTIONS_QUERY = gql`
  mutation updateUserSubscriptions($cityId: [Int], $key: String) {
    updateUserSubscriptions(cityId: $cityId, key: $key) {
      id
    }
  }
`;

const SubscriptionFormFields = ({
  countries,
  setFieldValue,
  setFieldTouched,
}: {
  countries: any;
  setFieldValue?: () => void;
  setFieldTouched?: () => void;
}) => {
  const subscriptionOptions: any = {};
  return (
    <Box mb="32px">
      {countries.edges.map(({ node }: { node: any }) => {
        subscriptionOptions[node.siteId] = [];
        node.subscriptions.edges.map(
          ({ node: subscriptionNode }: { node: any }) =>
            subscriptionOptions[node.siteId].push({
              inputLabel: subscriptionNode.title,
              inputValue: `${subscriptionNode.id}`,
            }),
        );
        const hasCheckedValues = node.subscriptions.edges.filter(
          ({ node: subscriptionNode2 }: { node: any }) =>
            subscriptionNode2.isChecked,
        );
        return (
          <Accordion
            title={node.country}
            key={node.id}
            isActive={
              countries.edges[0].node.id === node.id ||
              hasCheckedValues.length > 0
            }
          >
            <Field
              component={ColumnCheckboxGroup}
              name={`contactPermission_${node.siteId}`}
              options={subscriptionOptions[node.siteId]}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </Accordion>
        );
      })}
    </Box>
  );
};

SubscriptionFormFields.propTypes = {
  countries: PropTypes.shape({
    edges: PropTypes.any,
  }),
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

const SubscriptionsForm = ({
  status,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  values,
  ...props
}: BaseFormProps) => (
  <FormLayout
    handleSubmit={handleSubmit}
    status={status}
    isSubmitting={isSubmitting}
    {...props}
  >
    <SubscriptionFormFields setFieldValue={setFieldValue} {...props} />
    <Flex justifyContent={['center', 'left']}>
      <Button type="submit" kind="cta" isLoading={isSubmitting}>
        Save changes
      </Button>
      <Button
        type="submit"
        isLoading={isSubmitting}
        ml={10}
        onClick={(e: any) => {
          if (setFieldValue) {
            Object.keys(values).map((key) => setFieldValue(key, []));
            handleSubmit(e);
          }
        }}
      >
        Unsubscribe all
      </Button>
    </Flex>
  </FormLayout>
);

SubscriptionsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.any.isRequired,
  isSubmitting: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

SubscriptionsForm.defaultProps = {};

export default compose(
  graphql(UPDATE_USER_SUBSCRIPTIONS_QUERY, { name: 'updateUserSubscriptions' }),
  withFormik<any, any>({
    mapPropsToValues: ({ countries }: { countries: any }) => {
      const initialState: any = {};
      countries.edges.map(({ node }: { node: any }) => {
        initialState[`contactPermission_${node.siteId}`] = [];
        node.subscriptions.edges.map(
          ({ node: subscriptionNode }: { node: any }) => {
            if (subscriptionNode.isChecked) {
              // setup initial values
              initialState[`contactPermission_${node.siteId}`].push(
                `${subscriptionNode.id}`,
              );
            }
            return true;
          },
        );
        return true;
      });
      return initialState;
    },
    handleSubmit: async (
      values,
      {
        setSubmitting,
        setStatus,
        props: { updateUserSubscriptions, userKey },
      }: FormikBag<
        {
          updateUserSubscriptions: (i: any) => Promise<any>;
          userKey?: string;
        },
        any
      >,
    ) => {
      setStatus(false);
      const cityId: number[] = [];
      // push country values into one array
      Object.keys(values).map((val) => {
        const activeSubs = values[val].map((item: number) => +item);
        return cityId.push(...activeSubs);
      });
      await updateUserSubscriptions({
        variables: {
          cityId,
          key: userKey,
        },
      }).catch((error: any) => {
        setSubmitting(false);
        return setStatus({ message: errorMessage(error), type: 'danger' });
      });
      setSubmitting(false);
      return setStatus({
        message: 'Your subscriptions were updated',
        type: 'success',
      });
    },
  }),
)(SubscriptionsForm);
