import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import BodyTitle from '@firsttable/web-components/atoms/BodyTitle';
import PageLoading from '@firsttable/web-components/molecules/PageLoading';
import Text from '@firsttable/web-components/atoms/Text';
import { currencyFormat } from '@firsttable/functions';
import {
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '@firsttable/web-components/atoms/Table';
import ErrorDisplay from '@firsttable/web-components/molecules/ErrorDisplay';
import NavLink from '../../atoms/NavLink';

const GET_ACCOUNT_BALANCES = gql`
  query getAccountBalances {
    user: Member {
      id
      accountBalances {
        edges {
          node {
            amount
            currency
          }
        }
        pageInfo {
          totalCount
        }
      }
      expiringCredit {
        edges {
          node {
            title
            amount
            currency
            expires
          }
        }
        pageInfo {
          totalCount
        }
      }
    }
  }
`;

const AccountBalance = () => {
  const { loading, data, error } = useQuery(GET_ACCOUNT_BALANCES, {
    fetchPolicy: 'network-only',
  });
  if (loading) return <PageLoading />;
  if (error || !data?.user) return <ErrorDisplay NavLinkComponent={NavLink} />;
  const { user } = data;
  const creditCount = user.expiringCredit.pageInfo.totalCount;

  return (
    <>
      <BodyTitle size="m">Account Balance</BodyTitle>
      {user.accountBalances.edges.map(({ node }) => (
        <Fragment key={node.currency}>
          <Text>Your account balance is currently:</Text>
          <Text>
            Currency: {node.currency}{' '}
            {currencyFormat(node.amount, node.currency)}.
          </Text>
        </Fragment>
      ))}
      <BodyTitle size="m">Expiring Credits</BodyTitle>
      {creditCount === 0 ? (
        <Text>You don&apos;t have any credits expiring.</Text>
      ) : (
        <>
          <Table>
            <THead>
              <Tr>
                <Th>Credit</Th>
                <Th>Value</Th>
                <Th>Expires</Th>
              </Tr>
            </THead>
            <TBody>
              {user.expiringCredit.edges.map(({ node }, i) => (
                <Tr key={i}>
                  <Td>{node.title}</Td>
                  <Td>{currencyFormat(node.amount, node.currency)}</Td>
                  <Td>{node.expires}</Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </>
      )}
    </>
  );
};

AccountBalance.propTypes = {};
AccountBalance.defaultProps = {};

export default AccountBalance;
