import React from 'react';
import PropTypes from 'prop-types';
import { graphql as apolloGraphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import moment from 'moment';
import { createReview } from '@firsttable/graphql-queries/src';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import BodyTitle from '@firsttable/web-components/atoms/BodyTitle';
import { Table, Td, Tr } from '@firsttable/web-components/atoms/Table';
import { Title, Text, Box } from '@firsttable/web-components/atoms';
import {
  convertToLocalDate,
  currencyFormat,
  timeOfBooking,
} from '@firsttable/functions';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import { graphql, useStaticQuery } from 'gatsby';
import withBooking from '../../../hocs/booking';
import parseHtml from '../../../helpers/parseHtml';

const CancelBox = styled(Box)`
  background-color: ${themeGet('colors.warning.light')};
  margin: 20px 0;
  padding: 20px;
`;

const CancelText = styled(Box)`
  h3,
  h4 {
    margin: 0;
  }

  p {
    margin-top: 5px;
  }
`;

const CancellationText = ({ cancellationCredit: { reasonContent } }) => {
  if (reasonContent) {
    return <Text>{parseHtml(reasonContent)}</Text>;
  }
  return null;
};

CancellationText.propTypes = {
  cancellationCredit: PropTypes.shape({
    currency: PropTypes.string,
    reasonContent: PropTypes.string,
    amount: PropTypes.number,
    nonCreditedAmount: PropTypes.number,
    reason: PropTypes.oneOf([
      'NO_CREDIT',
      'TODAY_BOOKING',
      'WITHIN_60_MINUTES',
      'FUTURE_BOOKING',
    ]),
  }),
};

const CancellationForm = ({ booking, ...props }) => {
  const { setting } = useStaticQuery(graphql`
    query {
      setting(key: { eq: "CancellationPolicy" }) {
        content
      }
    }
  `);
  return (
    <FormLayout {...props} actionLabel="Process Cancellation">
      <Title as="h4" size="h6">
        Cancel Reservation
      </Title>
      <Table>
        <Tr>
          <Td>
            <Text as="span" fontWeight="600" fontSize="s">
              Restaurant:
            </Text>
          </Td>
          <Td>{booking.restaurant.title}</Td>
        </Tr>
        <Tr>
          <Td>
            <Text as="span" fontWeight="600" fontSize="s">
              Date:
            </Text>
          </Td>
          <Td>
            {moment(booking.date).format('dddd D MMM YYYY')},{' '}
            {timeOfBooking(booking.time)}
          </Td>
        </Tr>
        <Tr>
          <Td>
            <Text as="span" fontWeight="600" fontSize="s">
              Reservation made:
            </Text>
          </Td>
          <Td>{moment(convertToLocalDate(booking.confirmedAt)).fromNow()}</Td>
        </Tr>
      </Table>
      <CancelBox>
        <BodyTitle>
          Are you sure you wish to cancel your reservation at{' '}
          {booking.restaurant.title}?
        </BodyTitle>
        <CancellationText {...booking} />
        <Field
          component={FormField}
          type="checkbox"
          name="confirmCancellation"
          id="confirmCancellation"
          label="Yes, cancel my reservation"
        />
      </CancelBox>
      <CancelText>{setting && parseHtml(setting.content)}</CancelText>
    </FormLayout>
  );
};

CancellationForm.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  booking: PropTypes.shape(),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default compose(
  apolloGraphql(createReview, { name: 'updateBooking' }),
  withBooking,
  withFormik({
    mapPropsToValues: () => ({
      confirmCancellation: false,
    }),
    validationSchema: () =>
      Yup.object().shape({
        confirmCancellation: Yup.boolean()
          .oneOf([true], 'You must accept the cancellation terms')
          .required(),
      }),
    handleSubmit: async (
      values,
      { setSubmitting, props: { cancelBooking, booking, hideModal, refetch } },
    ) => {
      await cancelBooking({ id: booking.id });
      if (refetch) {
        setTimeout(() => hideModal(), 1000);
        refetch();
      }
      // update status on button
      setSubmitting(false);
    },
  }),
)(CancellationForm);
