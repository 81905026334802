import React from 'react';
import PropTypes from 'prop-types';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import Row from '@firsttable/web-components/atoms/Row/Row';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import Col from '@firsttable/web-components/atoms/Col/Col';
import MenuSidebar from '@firsttable/web-components/molecules/MenuSidebar';
import { hasPermission } from '@firsttable/functions';
import Link from '@firsttable/web-components/atoms/Link';
import GenericLayout from './GenericLayout';
import NavLinkComponent from '../components/atoms/NavLink';

const ProfileLayout = ({ children }) => (
  <GenericLayout>
    <ContainerContent>
      <RowHead title="Your Profile" />
      <Row gap={120}>
        <Col width={[1, 1, 1 / 3]} mb={['40px', '50px', 0]}>
          <MenuSidebar>
            <li>
              <Link NavComponent={NavLinkComponent} to="/profile/">
                Profile
              </Link>
            </li>
            <li>
              <Link
                NavComponent={NavLinkComponent}
                to="/profile/account-balance/"
              >
                Account Balance
              </Link>
            </li>
            <li>
              <Link NavComponent={NavLinkComponent} to="/profile/reservations/">
                Reservations
              </Link>
            </li>
            <li>
              <Link
                NavComponent={NavLinkComponent}
                to="/profile/subscriptions/"
              >
                Subscriptions
              </Link>
            </li>
            {hasPermission('PROMO_CODE') && (
              <li>
                <Link
                  NavComponent={NavLinkComponent}
                  to="/profile/promo-codes/"
                >
                  Promo Code
                </Link>
              </li>
            )}
          </MenuSidebar>
        </Col>
        <Col width={[1, 1, 2 / 3]} line hideLineOnMobile>
          {children}
        </Col>
      </Row>
    </ContainerContent>
  </GenericLayout>
);

ProfileLayout.propTypes = {
  children: PropTypes.node,
};

ProfileLayout.defaultProps = {
  children: PropTypes.node,
};

export default ProfileLayout;
