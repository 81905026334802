import React from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';
import PageLoading from '@firsttable/web-components/molecules/PageLoading';
import InputField from '@firsttable/web-components/molecules/InputField';
import {
  Button,
  Box,
  Text,
  BodyTitle,
  Divider,
} from '@firsttable/web-components/atoms';
import {
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '@firsttable/web-components/atoms/Table';
import ErrorDisplay from '@firsttable/web-components/molecules/ErrorDisplay';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes, { string } from 'prop-types';
import { useAlert } from 'react-alert';

import NavLink from '../../atoms/NavLink';

const GET_PROMO_CODE = gql`
  query getMemberPromoCode {
    user: Member {
      promoCode {
        id
        code
        bookings {
          edges {
            node {
              name
              hasBooked
              bookingCount
            }
          }
          pageInfo {
            totalCount
            hasNextPage
          }
        }
      }
    }
  }
`;

const CopyButton = ({ value }) => {
  const alert = useAlert();
  return (
    <CopyToClipboard text={value}>
      <Box m={['10px 0', '0 10px']} display="inline-block">
        <Button
          onClick={() =>
            alert.success('Promo code has been copied to clipboard')
          }
        >
          Copy to clipboard
        </Button>
      </Box>
    </CopyToClipboard>
  );
};

CopyButton.propTypes = {
  value: string.isRequired,
};

const PromoCodeForm = ({ location }) => (
  <Query query={GET_PROMO_CODE} fetchPolicy="no-cache">
    {({ loading, error, data }) => {
      if (loading) return <PageLoading />;
      if (error) {
        return <ErrorDisplay NavLinkComponent={NavLink} />;
      }
      if (!data) {
        return null;
      }
      const {
        user: { promoCode },
      } = data;
      const url = location.origin || 'www.firsttable.co.nz';

      return (
        <>
          <Box>
            <Box width={['100%', 400]} display="inline-block">
              <InputField
                mb={['15px', '20px']}
                name="code"
                size="l"
                type="text"
                label="Your promo code is"
                value={promoCode.code}
                disabled
                onBlur={() => {}}
                onChange={() => {}}
              />
            </Box>
            <CopyButton value={promoCode.code} />
          </Box>
          <Box>
            <Box width={['100%', 400]} display="inline-block">
              <InputField
                mb={['15px', '20px']}
                name="link"
                size="l"
                type="text"
                label="Your referral link is"
                value={`${url}/invite/${promoCode.code}`}
                disabled
                onBlur={() => {}}
                onChange={() => {}}
              />
            </Box>
            <CopyButton value={`${url}/invite/${promoCode.code}`} />
          </Box>
          <Divider m="35px 0" />
          <BodyTitle>Your sign ups</BodyTitle>
          {promoCode.bookings.pageInfo.totalCount > 0 ? (
            <Table>
              <THead>
                <Tr>
                  <Th>Friend&lsquo;s name</Th>
                  <Th>Made their first booking</Th>
                </Tr>
              </THead>
              <TBody>
                {promoCode.bookings.edges.map(({ node }, i) => (
                  <Tr key={i}>
                    <Td>{node.name}</Td>
                    <Td>{node.hasBooked ? 'Yes' : 'No'}</Td>
                  </Tr>
                ))}
              </TBody>
            </Table>
          ) : (
            <Text>No one&lsquo;s joined up with your promo code yet.</Text>
          )}
        </>
      );
    }}
  </Query>
);

PromoCodeForm.propTypes = {
  location: PropTypes.object,
};

PromoCodeForm.defaultProps = {
  location: {
    origin: '',
  },
};

export default PromoCodeForm;
