import React from 'react';
import PropTypes from 'prop-types';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import Row from '@firsttable/web-components/atoms/Row/Row';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import Col from '@firsttable/web-components/atoms/Col/Col';
import MainMenu from '../components/organisms/Navigation/Menu';
import Footer from '../components/organisms/Footer';
import ThemeLayout from './layout';

const FullWidthLayout = ({
  title, subTitle, children, align,
}) => (
  <ThemeLayout footer={<Footer />}>
    <MainMenu />
    <ContainerContent>
      <RowHead title={title} subTitle={subTitle} />
      <Row gap={164} display="flex" justifyContent="center">
        <Col
          width={[1, 2 / 3]}
          textAlign={align}
        >
          {children}
        </Col>
      </Row>
    </ContainerContent>
  </ThemeLayout>
);

FullWidthLayout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.any.isRequired,
};
FullWidthLayout.defaultProps = {
  title: '',
  subTitle: '',
  align: 'center',
};

export default FullWidthLayout;
