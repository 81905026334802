import React from 'react';
import PropTypes from 'prop-types';
import Container from '@firsttable/web-components/atoms/Container';
import Col from '@firsttable/web-components/atoms/Col';
import Row from '@firsttable/web-components/atoms/Row';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import ReviewSummary from '@firsttable/web-components/organisms/ReviewSummary/ReviewSummary';
import { dayMonthYear } from '@firsttable/functions';
import ReviewForm from '../Forms/ReviewForm';
import NavLink from '../../atoms/NavLink';

const ReviewModal = ({ booking, refetch, ...props }) => (
  <Container overflow="hidden" py={['30px', '66px', '100px']}>
    <RowHead
      title={`Review ${booking.restaurant.title}`}
      subTitle={`You dined at ${booking.restaurant.title} on ${dayMonthYear(
        booking.date,
      )}. Let us know how it went.`}
    />
    <Row>
      <Col>
        <ReviewForm booking={booking} modal refetch={refetch} {...props} />
      </Col>
      <Col>
        <ReviewSummary booking={booking} NavLinkComponent={NavLink} />
      </Col>
    </Row>
  </Container>
);

ReviewModal.defaultProps = {};
ReviewModal.propTypes = {
  booking: PropTypes.shape(),
  refetch: PropTypes.shape(),
};

export default ReviewModal;
